import { NgKLoggerService } from '@kin/ngk-logger';
import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';
import { PropertyInformationFormOption } from './property-information.types';

const EXPECTED_ATTRIBUTE_NAMES = ['within_park', 'bathrooms', 'acreage', 'purchase_date', 'roof_type', 'chimney', 'roof_solar_panels', 'solar_panel_status'] as const;
type ExpectedAttributeName = (typeof EXPECTED_ATTRIBUTE_NAMES)[number];

export function getOptionsForLegacyPagesApiPropertyInformationComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): PropertyInformationFormOption[] {
  return components
    .filter(isExpectedComponent)
    .map(componentToFormOption)
    .filter((option): option is PropertyInformationFormOption => option !== null);

  function isExpectedComponent(component: LegacyApiPagesComponent): component is LegacyApiPagesComponent & { attribute_name: ExpectedAttributeName } {
    if (EXPECTED_ATTRIBUTE_NAMES.includes(component.attribute_name as ExpectedAttributeName)) {
      return true;
    }
    logger.error({
      message: `Unknown attribute_name returned from legacy api: ${component.attribute_name}`,
      context: 'getOptionsForLegacyPagesApiPropertyInformationComponent',
      priority: 'P3',
    });
    return false;
  }

  function componentToFormOption(component: LegacyApiPagesComponent & { attribute_name: ExpectedAttributeName }): PropertyInformationFormOption | null {
    try {
      if (component.select_options) {
        return {
          id: component.attribute_name,
          label: component.label,
          selectOptions: component.select_options.map((option) => ({
            label: option.label,
            value: option.value,
          })),
        };
      }

      return {
        id: component.attribute_name,
        label: component.label,
      };
    } catch (error) {
      logger.error({
        message: `Error processing component: ${error instanceof Error ? error.message : 'Unknown error'}`,
        context: 'getOptionsForLegacyPagesApiPropertyInformationComponent',
        priority: 'P3',
      });
      return null;
    }
  }
}
