import { AppEnvironment } from '../app-config.model';

/**
 * Used to configure the SplitIO API key for each environment.
 * You can find the list of Kin's SplitIO API keys here: https://app.split.io/org/cbc88ea0-8647-11ea-bae8-0ed25467b33f/ws/cbd16840-8647-11ea-bae8-0ed25467b33f/admin/apis/sdkkeys.
 * You may notice that these are the same API keys used for ng-kin and efnol-ui; that is intentional.
 * See these SplitIO docs to learn more about how it is important to use different keys across different environments, but it is not important
 * to use different keys across different applications:
 * https://help.split.io/hc/en-us/articles/360033557092-SDK-overview#:~:text=under%20your%20control-,API%20keys,the%20appropriate%20API%20key%20type
 * https://help.split.io/hc/en-us/articles/360006854852-Frontend-and-backend-API-key-usage
 */
export const splitIOEnvConfig: ReadonlyMap<AppEnvironment, string> = new Map([
  ['local', 'ud9iosss7a1q2fv042c4625llh1dbbjoj6ca'],
  ['development', 'ud9iosss7a1q2fv042c4625llh1dbbjoj6ca'],
  ['staging', 'jg52msmphbtu6qhk9p3jj9vdm6mn6vve2dfn'],
  ['production', 'jgvvc544opgimfu37j6tvja06n1q998vbe0f'],
]);

export const FEATURE_FLAGS = {
  QT_1034_TCPA_CONSENT_EIG: 'QT-1034_TCPA_Consent_EIG', // Determines whether or not we show the EIG consent checkbox on about you
} as const;

export type FeatureFlag = keyof typeof FEATURE_FLAGS;
