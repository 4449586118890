import type { QuoteDraftSelectorState } from 'app/global-store/quote-draft';
import { PolicyGoalValue } from '../forms/policy-goals/policy-goals.types';

export interface QuoteReviewSelectorState {
  firstName: string | undefined;
  lastName: string | undefined;
  policyGoal: PolicyGoalValue | undefined;
  fullName: string | undefined;
  addressStateAbbreviated: string | undefined;
}

export function quoteReviewSelector(store: QuoteDraftSelectorState): QuoteReviewSelectorState {
  const { applicant } = store;
  const { firstName, lastName } = applicant() || {};
  const policyGoal = store.policyGoal() || undefined;
  const addressStateAbbreviated = store.address()?.state;
  let fullName;
  if (firstName && lastName) {
    fullName = `${firstName} ${lastName}`;
  }

  return { firstName, lastName, policyGoal, addressStateAbbreviated, fullName };
}
