import { LegacyQuotePagesPath } from '../../common/services/legacy/quote-pages';
import {
  BooleanString,
  LegacyApiPagesCirAboutYou,
  LegacyApiPagesCirCoApplicant,
  LegacyApiPagesCirCoApplicantInfo,
  LegacyApiPagesCirConstructionType,
  LegacyApiPagesCirExteriorWall,
  LegacyApiPagesCirFoundationType,
  LegacyApiPagesCirInsuranceStatus,
  LegacyApiPagesCirInsuranceStatusDays,
  LegacyApiPagesCirPolicyStartDate,
  LegacyApiPagesCirPolicyType,
  LegacyApiPagesCirPropertyInformation,
  LegacyApiPagesCirPropertyType,
  LegacyApiPagesCirRentalStatus,
  LegacyApiPagesCirResidency,
  LegacyApiPagesCirRoofShape,
  LegacyApiPagesCirRoofYear,
  LegacyApiPagesCirUnitNumberCheck,
  LegacyApiPagesCirUnitNumberEntry,
  LegacyApiPagesCirYearBuilt,
} from '../../common/services/legacy/quote-pages/models/customer-input-response.model';
import type { QuoteDraftSelectorState } from '../../global-store/quote-draft';

/**
 * Maps a the v1 Legacy Pages "path" property to the corresponding payload based on the store state.
 *
 * @param {QuoteDraftSelectorState} store - The Quote Draft Store - Signals
 * @param {LegacyQuotePagesPath} legacyPath - The legacy path to map to a payload.
 * @returns {LegacyApiPagesCirAboutYou | LegacyApiPagesCirPolicyType | undefined} - The mapped payload for the given legacy path.
 *
 * @todo - see if we can pass in a store instance as opposed signals
 */
export function mapLegacyPathToLegacyPayload(store: QuoteDraftSelectorState, legacyPath: LegacyQuotePagesPath) {
  if (!store || !legacyPath) {
    return undefined;
  }
  // pull properties from the Quote Draft Store
  const optInText = store.hasOptedIntoSms() ?? false;
  const { firstName = '', lastName = '', dob, email = '', phoneNumber = '' } = store.applicant() || {};
  const policyType = store.policyType()!;
  const hasUnitNumber = store.hasUnitNumber()!;
  const unitNumber = store.unitNumber()!;
  const currentInsuranceStatus = store.insuranceStatus()!;
  const willRent = store.rentalStatus()!;
  const timesRented = store.rentalNumber()!;
  const startDate = store.policyStartDate()!;
  const hasCoApplicant = store.hasCoApplicant()!;
  const monthsOccupied = store.residency()!;
  const propertyType = store.propertyType()!;
  const roofShape = store.roofShape()!;
  const isInsuranceExpiredMoreThan30Days = store.isInsuranceExpiredMoreThan30Days();
  const constructionType = store.constructionType()!;
  const exteriorWall = store.exteriorWall()!;
  const foundationType = store.foundationType()!;

  switch (legacyPath) {
    // ABOUT YOU
    case '/quote/about-you': {
      const payload: LegacyApiPagesCirAboutYou = {
        first_name: firstName,
        last_name: lastName,
        dob,
        email,
        phone_number: phoneNumber,
        opt_in_text: optInText,
      };
      return payload;
    }
    // POLICY TYPE
    case '/quote/policy_type': {
      const payload: LegacyApiPagesCirPolicyType = {
        policy_type: policyType,
      };
      return payload;
    }

    // UNIT NUMBER CHECK
    case '/quote/unit_number_check': {
      // false (boolean) = no, null = yes
      let uncPayload: boolean | null;
      if (hasUnitNumber) {
        uncPayload = null;
      } else {
        uncPayload = false;
      }

      const payload: LegacyApiPagesCirUnitNumberCheck = {
        unit_entry: 'true',
        unit_number_check: uncPayload,
      };
      return payload;
    }

    // UNIT NUMBER ENTRY
    case '/quote/unit_number_entry': {
      const payload: LegacyApiPagesCirUnitNumberEntry = {
        unit_number: unitNumber,
      };
      return payload;
    }

    // INSURANCE STATUS
    case '/quote/insurance_status': {
      let mappedInsuranceStatus = currentInsuranceStatus;

      // Map 'new_purchase_new_policy_first_time' to 'new_purchase_new_policy'
      if (currentInsuranceStatus === 'new_purchase_new_policy_first_time') {
        mappedInsuranceStatus = 'new_purchase_new_policy';
      }

      const payload: LegacyApiPagesCirInsuranceStatus = {
        already_insured: (mappedInsuranceStatus !== 'no_insurance').toString() as BooleanString,
        current_insurance_status: mappedInsuranceStatus === 'no_insurance' ? null : mappedInsuranceStatus,
      };
      return payload;
    }
    // RESIDENCY
    case '/quote/residency': {
      const payload: LegacyApiPagesCirResidency = {
        months_occupied: monthsOccupied,
      };
      return payload;
    }
    // RENTAL STATUS
    case '/quote/rental_status': {
      const payload: LegacyApiPagesCirRentalStatus = {
        plan_on_renting: 'true', // this needs to be hard coded as true
        times_rented: willRent ? null : 0, // null = yes will rent, 0 = no will not rent
      };
      return payload;
    }
    // RENTAL FREQUENCY
    case '/quote/rental_status_number': {
      const payload: LegacyApiPagesCirRentalStatus = {
        plan_on_renting: 'true',
        times_rented: timesRented,
      };
      return payload;
    }
    // POLICY START DATE
    case '/quote/policy_start_date': {
      const payload: LegacyApiPagesCirPolicyStartDate = {
        effective_date: startDate,
      };
      return payload;
    }
    // CO APPLICANT
    case '/quote/co-applicant': {
      const payload: LegacyApiPagesCirCoApplicant = {
        include_coapplicant: hasCoApplicant ? null : 'false', // false = no, null = yes
        include_coapplicant_info: 'true',
      };
      return payload;
    }

    // CO APPLICANT INFO
    case '/quote/co-applicant-info': {
      const { firstName: coFirstName = '', lastName: coLastName = '', dob: coDob } = store.coApplicant() || {};
      const payload: LegacyApiPagesCirCoApplicantInfo = {
        coapplicant_first_name: coFirstName,
        coapplicant_last_name: coLastName,
        coapplicant_dob: coDob,
        include_coapplicant: 'true',
      };
      return payload;
    }

    // PROPERTY DETAILS - PROPERTY TYPE
    case '/quote/property-details/property-type': {
      const payload: LegacyApiPagesCirPropertyType = {
        property_type: propertyType,
      };
      return payload;
    }

    // PROPERTY DETAILS - ROOF SHAPE
    case '/quote/property-details/roof-shape': {
      const payload: LegacyApiPagesCirRoofShape = {
        roof_shape: roofShape,
      };
      return payload;
    }

    case '/quote/insurance_status_days': {
      let status: 'expired_less_than_30_days' | 'expired_more_than_30_days' | undefined;
      switch (isInsuranceExpiredMoreThan30Days) {
        case true:
          status = 'expired_more_than_30_days';
          break;
        case false:
          status = 'expired_less_than_30_days';
          break;
        default:
          status = undefined;
      }
      const payload: LegacyApiPagesCirInsuranceStatusDays = {
        current_insurance_status: status,
      };
      return payload;
    }

    case '/quote/property-details/year-built': {
      const yearBuilt = store.yearBuilt();
      const sqFt = store.sqFt();
      const stories = store.stories();
      const pool = store.pool();

      const payload: LegacyApiPagesCirYearBuilt = {
        year_built: yearBuilt ? yearBuilt.toString() : undefined,
        sq_ft: sqFt ? sqFt.toString() : undefined,
        stories: stories ? stories.toString() : undefined,
        pool: pool ?? undefined,
      };

      return payload;
    }

    // PROPERTY DETAILS - CONSTRUCTION TYPE
    case '/quote/property-details/construction-type': {
      const payload: LegacyApiPagesCirConstructionType = {
        construction_type: constructionType,
      };
      return payload;
    }

    // PROPERTY DETAILS - ROOF YEAR
    case '/quote/property-details/roof-year': {
      const roofYear = store.roofYear();

      const payload: LegacyApiPagesCirRoofYear = {
        roof_construction_year: roofYear ? roofYear.toString() : undefined,
      };
      return payload;
    }

    // PROPERTY DETAILS - EXTERIOR WALL
    case '/quote/property-details/exterior-wall': {
      const payload: LegacyApiPagesCirExteriorWall = {
        exterior_wall_finish: exteriorWall,
      };
      return payload;
    }

    // PROPERTY INFORMATION - ALL QUESTIONS
    case '/quote/property_information': {
      const purchaseDate = store.purchaseDate();
      const payload: LegacyApiPagesCirPropertyInformation = {
        within_park: store.withinPark() ?? undefined,
        bathrooms: store.bathrooms()?.toString() ?? undefined,
        acreage: store.acreage()?.toString() ?? undefined,
        purchase_date: purchaseDate ?? undefined,
        roof_type: store.roofType() ?? undefined,
        chimney: store.chimney() ?? undefined,
        roof_solar_panels: store.roofHasSolarPanels() ?? undefined,
        solar_panel_status: store.solarPanelStatus() ?? undefined,
      };
      return payload;
    }

    // PROPERTY DETAILS - FOUNDATION
    case '/quote/property-details/foundation': {
      const payload: LegacyApiPagesCirFoundationType = {
        foundation: foundationType || undefined,
      };
      return payload;
    }

    default:
      return undefined;
  }
}
