import { computed } from '@angular/core';
import { signalStoreFeature, type, withComputed } from '@ngrx/signals';
import { hasCoApplicantQuoteFormSelector } from 'app/create-quote/forms/co-applicant/co-applicant.selector';
import { insuranceStatusQuoteFormSelector } from 'app/create-quote/forms/insurance-status/insurance-status.selector';
import { policyTypeQuoteFormSelector } from 'app/create-quote/forms/policy-type/policy-type.selector';
import { constructionTypeQuoteFormSelector } from 'app/create-quote/forms/property-details/construction-type/construction-type.selector';
import { exteriorWallQuoteFormSelector } from 'app/create-quote/forms/property-details/exterior-wall/exterior-wall.selector';
import { foundationTypeQuoteFormSelector } from 'app/create-quote/forms/property-details/foundation/foundation.selector';
import { propertyTypeQuoteFormSelector } from 'app/create-quote/forms/property-details/property-type/property-type.selector';
import { roofShapeQuoteFormSelector } from 'app/create-quote/forms/property-details/roof-shape/roof-shape.selector';
import { rentalNumberQuoteFormSelector } from 'app/create-quote/forms/rental-number/rental-number.selector';
import { rentalStatusQuoteFormSelector } from 'app/create-quote/forms/rental-status/rental-status.selector';
import { unitNumberCheckQuoteFormSelector } from 'app/create-quote/forms/unit-number-check/unit-number-check.selector';
import { unitNumberEntryQuoteFormSelector } from 'app/create-quote/forms/unit-number-entry/unit-number-entry.selector';
import { quoteReviewSelector } from 'app/create-quote/quote-review/quote-review.selector';
import { aboutYouQuoteFormSelector } from '../../../create-quote/forms/about-you/about-you.selector';
import { coApplicantInfoQuoteFormSelector } from '../../../create-quote/forms/co-applicant-info/co-applicant-info.selector';
import { insuranceExpirationQuoteFormSelector } from '../../../create-quote/forms/insurance-expiration/insurance-expiration.selector';
import { policyGoalsQuoteFormSelector } from '../../../create-quote/forms/policy-goals/policy-goals.selector';
import { roofYearQuoteFormSelector } from '../../../create-quote/forms/property-details/roof-year/roof-year.selector';
import { yearBuiltQuoteFormSelector } from '../../../create-quote/forms/property-details/year-built/year-built.selector';
import { propertyInformationQuoteFormSelector } from '../../../create-quote/forms/property-information/property-information.selector';
import { QuoteDraftState } from '../quote-draft.model';

/**
 * Selectors are used to pull data from the store and provide it to a component or service
 * QuoteFormSelectors are bespoke to a quoteForm and live with the form directory files.
 */
export function withQuoteDraftSelectors() {
  return signalStoreFeature(
    { state: type<QuoteDraftState>() },
    withComputed((store) => ({
      /**
       * global selectors
       */
      isLoading: computed(() => store.storeStatus() === 'loading'),
      isError: computed(() => typeof store.storeStatus() === 'object'),
      address: computed(() => store.address()),
      displayAddress: computed(() => {
        const address = store.address();
        const unitNumber = store.unitNumber();
        const displayUnitNumber = unitNumber && !address?.unit ? `${unitNumber}` : address?.unit;
        const combinedWithUnitNumber = `${address?.street} #${displayUnitNumber}, ${address?.city}, ${address?.state} ${address?.zipcode}`;
        const combinedNoUnitNumber = `${address?.street}, ${address?.city}, ${address?.state} ${address?.zipcode}`;
        return address?.street ? `${displayUnitNumber ? combinedWithUnitNumber : combinedNoUnitNumber}` : '';
      }),
      quoteData: computed(() => {
        return {
          package_type: store.selectedPackage(),
          customer_response_id: store.cirId(),
          address: store.address(),
          clickLeadsAddress: store.clickLeadsAddress(),
          applicant: store.applicant(),
          hasOptedIntoSms: store.hasOptedIntoSms(),
          policyType: store.policyType(),
          policyGoal: store.policyGoal(),
          hasUnitNumber: store.hasUnitNumber(),
          unitNumber: store.unitNumber(),
          insuranceStatus: store.insuranceStatus(),
          rentalStatus: store.rentalStatus(),
          rentalNumber: store.rentalNumber(),
          residency: store.residency(),
          policyStartDate: store.policyStartDate(),
          hasCoApplicant: store.hasCoApplicant(),
          coApplicant: store.coApplicant(),
          propertyType: store.propertyType(),
          selectedPackageInfo: store.selectedPackageInfo(),
          roofShape: store.roofShape(),
          isInsuranceExpiredMoreThan30Days: store.isInsuranceExpiredMoreThan30Days(),
          yearBuilt: store.yearBuilt(),
          sqFt: store.sqFt(),
          stories: store.stories(),
          pool: store.pool(),
          constructionType: store.constructionType(),
          roofYear: store.roofYear(),
          exteriorWall: store.exteriorWall(),
          withinPark: store.withinPark(),
          bathrooms: store.bathrooms(),
          roofHasSolarPanels: store.roofHasSolarPanels(),
          acreage: store.acreage(),
          purchaseDate: store.purchaseDate(),
          roofType: store.roofType(),
          foundationType: store.foundationType(),
        };
      }),
      /**
       * quote form selectors
       */
      aboutYouQuoteFormSelector: computed(() => aboutYouQuoteFormSelector(store)),
      policyTypeQuoteFormSelector: computed(() => policyTypeQuoteFormSelector(store)),
      unitNumberCheckQuoteFormSelector: computed(() => unitNumberCheckQuoteFormSelector(store)),
      unitNumberEntryQuoteFormSelector: computed(() => unitNumberEntryQuoteFormSelector(store)),
      insuranceStatusQuoteFormSelector: computed(() => insuranceStatusQuoteFormSelector(store)),
      rentalStatusQuoteFormSelector: computed(() => rentalStatusQuoteFormSelector(store)),
      rentalNumberQuoteFormSelector: computed(() => rentalNumberQuoteFormSelector(store)),
      hasCoApplicantQuoteFormSelector: computed(() => hasCoApplicantQuoteFormSelector(store)),
      propertyTypeQuoteFormSelector: computed(() => propertyTypeQuoteFormSelector(store)),
      insuranceExpirationQuoteFormSelector: computed(() => insuranceExpirationQuoteFormSelector(store)),
      selectedPackageInfoSelector: computed(() => store.selectedPackageInfo()),
      roofShapeQuoteFormSelector: computed(() => roofShapeQuoteFormSelector(store)),
      coApplicantInfoQuoteFormSelector: computed(() => coApplicantInfoQuoteFormSelector(store)),
      policyGoalsQuoteFormSelector: computed(() => policyGoalsQuoteFormSelector(store)),
      yearBuiltQuoteFormSelector: computed(() => yearBuiltQuoteFormSelector(store)),
      constructionTypeQuoteFormSelector: computed(() => constructionTypeQuoteFormSelector(store)),
      roofYearQuoteFormSelector: computed(() => roofYearQuoteFormSelector(store)),
      exteriorWallQuoteFormSelector: computed(() => exteriorWallQuoteFormSelector(store)),
      propertyInformationQuoteFormSelector: computed(() => propertyInformationQuoteFormSelector(store)),
      foundationTypeQuoteFormSelector: computed(() => foundationTypeQuoteFormSelector(store)),
      quoteReviewSelector: computed(() => quoteReviewSelector(store)),
    })),
  );
}
