<form [formGroup]="policyGoalsForm" (ngSubmit)="handleSubmit($event)" id="quote-form" class="qui-form-layout__col-center flex flex-col gap-400">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card
    [title]="'What is most important to you for your insurance policy?'"
    subcopy="Your answer will help us build a quote that meets your needs. You can adjust your coverage details once you see your quote."
    icon="lucideGoal"
  >
    <div class="grid grid-cols-1">
      <fieldset class="form-group__fieldset" [appReadonlyRadioGroup]="isFieldLocked('policyGoal')">
        <legend class="sr-only">Select your policy goal</legend>
        <div class="grid gap-300 min-[1270px]:grid-cols-3">
          @for (policyGoalOption of policyGoalOptions; track policyGoalOption) {
            <label
              class="radio-card group w-full focus-within:ring-2 focus-within:ring-primary"
              [class.radio-card--invalid]="showError"
              [for]="policyGoalOption.id"
              [attr.data-testid]="'policy-goal-' + policyGoalOption.id"
            >
              <div class="flex w-full flex-row items-start">
                <ng-icon [name]="policyGoalOption.icon" size="1.5em" class="!overflow-visible" aria-hidden="true"></ng-icon>
                <p data-content="policyGoalOption.label" class="m-l-200 font-medium group-has-[:checked]:font-bold">{{ policyGoalOption.label }}</p>
              </div>

              <input class="radio-card__control peer outline-none" type="radio" [id]="policyGoalOption.id" [value]="policyGoalOption.value" formControlName="policyGoal" />
            </label>
          }
        </div>
      </fieldset>

      <div id="policy-goals-message" class="form-help mt-0" aria-live="polite" aria-atomic="true">
        @if (showError) {
          <div class="form-help--invalid mt-300 flex items-center gap-100" id="policy-goals-error">
            <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
            Please make a selection
          </div>
        }
      </div>
    </div>
  </app-form-card>
</form>
