import { QuoteDraftState } from './quote-draft.model';

export const quoteDraftInitialState: QuoteDraftState = {
  storeStatus: undefined,
  cirId: undefined,
  draftToken: undefined,
  address: undefined,
  clickLeadsAddress: undefined,
  hasOptedIntoSms: true,
  applicant: undefined,
  policyType: undefined,
  hasUnitNumber: undefined,
  unitNumber: undefined,
  insuranceStatus: undefined,
  rentalStatus: undefined,
  rentalNumber: undefined,
  residency: undefined,
  policyStartDate: undefined,
  hasCoApplicant: undefined,
  coApplicant: undefined,
  propertyType: undefined,
  selectedPackageInfo: undefined,
  roofShape: undefined,
  isInsuranceExpiredMoreThan30Days: undefined,
  policyGoal: undefined,
  yearBuilt: undefined,
  sqFt: undefined,
  stories: undefined,
  pool: undefined,
  constructionType: undefined,
  roofYear: undefined,
  exteriorWall: undefined,
  withinPark: undefined,
  bathrooms: undefined,
  chimney: undefined,
  roofHasSolarPanels: undefined,
  solarPanelStatus: undefined,
  acreage: undefined,
  purchaseDate: undefined,
  roofType: undefined,
  foundationType: undefined,
  selectedPackage: undefined,
  submittedFields: {},
};
